<script>
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";
import { SuawInputGroup, SuawButton, SuawHeading } from "@/components";
export default {
  name: "EventsToolkit",
  components: { ResourceFrame, SuawInputGroup, SuawButton, SuawHeading },
  data() {
    return {
      sideNavLinks: [
        { to: { name: "InPersonEventChecklist" }, name: "In-Person Event Checklist" },
        { to: { name: "OnlineEventChecklist" }, name: "Online Event Checklist" },
        { to: { name: "EventsToolkit", hash: "#table-tent" }, name: "In-Person Event Table Tent" },
        { to: { name: "EventsToolkit", hash: "#flyers" }, name: "In-Person Event Flyer" },
        { to: { name: "EventsToolkit", hash: "#online-flyer" }, name: "Online Event Flyer" },
        { to: { name: "EventsToolkit", hash: "#social-media" }, name: "Social Media Templates" },
        { to: { name: "EventsToolkit", hash: "#desktop" }, name: "Desktop Images" }
      ]
    };
  },
  watch: {
    $route() {
      this.scrollToSection();
    }
  },
  mounted() {
    this.scrollToSection();
  },
  methods: {
    scrollToSection() {
      if (this.$route.hash) {
        this.$nextTick(() => {
          const element = document.querySelector(this.$route.hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    },
    downloadPdf(filename) {
      const link = document.createElement("a");
      link.href = `/img/resources/events-toolkit/${filename}`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveImage(filename) {
      const link = document.createElement("a");
      link.href = `/img/resources/events-toolkit/${filename}`;
      // Keep the original file extension
      const saveName = filename;
      link.download = saveName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<template>
  <ResourceFrame :custom-links="sideNavLinks">
    <SuawInputGroup direction="column" group-gap="septuple" prevent-flex-grow>
      <h1 class="suaw-resource-heading"><b>Events Toolkit</b></h1>
      <SuawHeading class="suaw-organizer-resource-intro" level="3" content="Download these resources to help you promote and run your Shut Up & Write! events." />
      <SuawInputGroup direction="column" group-gap="double" prevent-flex-grow>
        <h2 id="checklists" class="suaw-organizer-resource-subtitle">
          Checklists
        </h2>
        <div class="suaw-get-involved-guide">
          <ul class="suaw-get-involved-guide__list">
            <li class="suaw-get-involved-guide__item">
              <p class="suaw-paragraph">
                <router-link :to="{ name: 'InPersonEventChecklist' }">In-Person Event Checklist</router-link>
              </p>
            </li>
            <li class="suaw-get-involved-guide__item">
              <p class="suaw-paragraph">
                <router-link :to="{ name: 'OnlineEventChecklist' }">Online Event Checklist</router-link>
              </p>
            </li>
          </ul>
        </div>
      </SuawInputGroup>
      <SuawInputGroup direction="column" group-gap="base" prevent-flex-grow>
        <h2 id="table-tent" class="suaw-organizer-resource-subtitle">
          In-Person Event Table Tent
        </h2>
        <div class="suaw-resource-pdf-preview">
          <div class="suaw-resource-button-container">
            <SuawButton
              button-text="Download Table Tent"
              size="large"
              type="success"
              icon-left="IconDownload"
              class="suaw-resource-download-button"
              @click="downloadPdf('suaw-table-tent.pdf')"
            />
          </div>
          <img src="/img/resources/events-toolkit/suaw-table-tent.png" alt="Table Tent preview" class="suaw-resource-preview-image" />
        </div>
      </SuawInputGroup>

      <SuawInputGroup direction="column" group-gap="base" prevent-flex-grow>
        <h2 id="flyers" class="suaw-organizer-resource-subtitle">
          In-Person Event Flyer
        </h2>
        <div class="suaw-resource-pdf-preview">
          <div class="suaw-resource-button-container">
            <SuawButton
              class="suaw-resource-download-button"
              button-text="Download In-Person Event Flyer"
              size="large"
              type="success"
              icon-left="IconDownload"
              @click="downloadPdf('in-person-event-flyer.pdf')"
            />
          </div>
          <img src="/img/resources/events-toolkit/in-person-event-flyer.png" alt="In-Person Event Flyer preview" class="suaw-resource-preview-image" />
        </div>
      </SuawInputGroup>
      <SuawInputGroup direction="column" group-gap="base" prevent-flex-grow>
        <h2 id="online-flyer" class="suaw-organizer-resource-subtitle">
          Online Event Flyer
        </h2>
        <div class="suaw-resource-pdf-preview">
          <div class="suaw-resource-button-container">
            <SuawButton
              class="suaw-resource-download-button"
              button-text="Download Online Event Flyer"
              size="large"
              type="success"
              icon-left="IconDownload"
              @click="downloadPdf('online-event-flyer.pdf')"
            />
          </div>
          <img src="/img/resources/events-toolkit/online-event-flyer.png" alt="Online Event Flyer preview" class="suaw-resource-preview-image" />
        </div>
      </SuawInputGroup>

      <h1 id="social-media" class="suaw-resource-heading"><b>Social Media Templates</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Use these templates to advertise your Shut Up & Write! events on Instagram by including your event's time, date, and location in the caption of your post."
      />
      <SuawInputGroup direction="column" group-gap="base" prevent-flex-grow>
        <div class="suaw-resource-pdf-preview">
          <div class="suaw-resource-button-container">
            <SuawButton
              class="suaw-resource-download-button"
              button-text="Save Social Media Banner"
              size="large"
              type="success"
              icon-left="IconDownload"
              @click="saveImage('suaw-social-media-banner.png')"
            />
          </div>
          <img src="/img/resources/events-toolkit/suaw-social-media-banner.png" alt="Social Media Banner preview" class="suaw-resource-preview-image suaw-resource-social-image" />
        </div>
      </SuawInputGroup>


      <div class="suaw-resource-pdf-preview">
        <div class="suaw-resource-button-container">
          <SuawButton
            button-text="Save Social Media Template 1"
            size="large"
            type="success"
            iconLeft="IconDownload"
            @click="saveImage('suaw-social-media-post-template-1.png')"
            class="suaw-resource-download-button"
          />
        </div>
        <img src="/img/resources/events-toolkit/suaw-social-media-post-template-1.png" alt="Social Media Post Template 1 preview" class="suaw-resource-preview-image suaw-resource-social-image" />
      </div>

      <div class="suaw-resource-pdf-preview">
        <div class="suaw-resource-button-container">
          <SuawButton
            button-text="Save Social Media Template 2"
            size="large"
            type="success"
            iconLeft="IconDownload"
            @click="saveImage('suaw-social-media-post-template-2.png')"
            class="suaw-resource-download-button"
          />
        </div>
        <img src="/img/resources/events-toolkit/suaw-social-media-post-template-2.png" alt="Social Media Post Template 2 preview" class="suaw-resource-preview-image suaw-resource-social-image" />
      </div>

      <h1 id="desktop" class="suaw-resource-heading"><b>Desktop Images</b></h1>
      <SuawHeading
        class="suaw-organizer-resource-intro"
        level="3"
        content="Download these images to use as desktop backgrounds and show your Shut Up & Write! pride."
      />

      <div class="suaw-resource-pdf-preview">
        <div class="suaw-resource-button-container">
          <SuawButton
            button-text="Save Pink Desktop Background"
            size="large"
            type="success"
            iconLeft="IconDownload"
            @click="saveImage('suaw-download-background-pink.png')"
            class="suaw-resource-download-button"
          />
        </div>
        <img src="/img/resources/events-toolkit/suaw-download-background-pink.png" alt="Pink Desktop Background preview" class="suaw-resource-preview-image" />
      </div>

      <div class="suaw-resource-pdf-preview">
        <div class="suaw-resource-button-container">
          <SuawButton
            button-text="Save Blue Desktop Background"
            size="large"
            type="success"
            iconLeft="IconDownload"
            @click="saveImage('suaw-download-background-blue.png')"
            class="suaw-resource-download-button"
          />
        </div>
        <img src="/img/resources/events-toolkit/suaw-download-background-blue.png" alt="Blue Desktop Background preview" class="suaw-resource-preview-image" />
      </div>

      <div class="suaw-resource-pdf-preview">
        <div class="suaw-resource-button-container">
          <SuawButton
            button-text="Save Grey Desktop Background"
            size="large"
            type="success"
            iconLeft="IconDownload"
            @click="saveImage('suaw-download-background-grey.png')"
            class="suaw-resource-download-button"
          />
        </div>
        <img src="/img/resources/events-toolkit/suaw-download-background-grey.png" alt="Grey Desktop Background preview" class="suaw-resource-preview-image" />
      </div>
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-resource-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-organizer-resource-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-resource-pdf-preview {
  // margin: 24px 0;
  display: flex;
  flex-direction: column;
}

.suaw-resource-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}

.suaw-resource-preview-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &.suaw-resource-social-image {
    max-width: 375px;
    width: 100%;

    @media (max-width: 650px) {
      margin: 0 auto;
    }
  }
}

.suaw-resource-button-container {
  width: fit-content;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.suaw-resource-download-button {
  margin: 0 0 16px 0;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.suaw-get-involved-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
